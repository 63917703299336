#banner {
  box-sizing: border-box;
  overflow: hidden;
}

.banner-btn {
  background-color: #ee9d0f;
  box-shadow: inset;
}

.banner-btn:hover {
  background-color: #b97d15;
}
