.hero-main {
  min-height: 70vh;
}

.payment-main {
  width: 100%;
}

.payment-heading h4 span {
  color: #e0016e;
}
